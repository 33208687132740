import React, { useState, useEffect } from 'react'
import { useDebounce } from 'packages/core'
import { TextInput } from 'packages/eid-ui'

const TextualFilter = ({ value, onChange,placeholder, ...rest }) => {
    const [searchKey, setSearchKey] = useState(value)

    useEffect(() => {
        if (value !== searchKey) {
            setSearchKey(value)
        }
    }, [value, searchKey])

    const debouncedValue = useDebounce(searchKey)

    useEffect(() => {
        onChange(debouncedValue)
    }, [debouncedValue, onChange])

    return (
        <TextInput
            {...rest}
            value={searchKey}
            handleChange={setSearchKey}
            placeholder={placeholder}
        />
    )
}

export default TextualFilter
